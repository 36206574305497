import { render, staticRenderFns } from "./EditProformatComponent.vue?vue&type=template&id=37630b44&scoped=true"
import script from "./EditProformatComponent.vue?vue&type=script&lang=js"
export * from "./EditProformatComponent.vue?vue&type=script&lang=js"
import style0 from "./EditProformatComponent.vue?vue&type=style&index=0&id=37630b44&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37630b44",
  null
  
)

export default component.exports