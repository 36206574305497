import {mapState} from "vuex";
import {ENDPOINTS} from "../../config";
import {Events} from "../../plugins/events";

export default {
    computed:{
        ...mapState([
            'api',
        ]),
    },
    methods:{
        async getActiveShift() {
            await this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
                cashierID: this.api.auth.cashierID
            }).then((res) => {
                if (res.data.success) {
                    //ATTACH ACTIVE SHIFT
                    this.$store.dispatch("pos/updateActiveShift", res.data.activeShift);
                } else {
                    this.$swal({
                        title:this.$t('generic.lang_startShift'),
                        text: this.$t('generic.lang_noShiftStartedMsg'),
                        icon: "warning",
                        showLoaderOnDeny: true,
                        showDenyButton: true,
                        denyButtonText: this.$t('generic.lang_cancel'),
                        confirmButtonText: this.$t('generic.lang_ok'),
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                            if(this.$store.getters['permissions/checkModule'](1)&&this.$store.getters['permissions/checkModule'](2)){
                                this.$router.replace('/pos/gastro/tresenverkauf');
                            }else if (this.$store.getters['permissions/checkModule'](2)){
                                this.$router.replace('/pos/gastro/tresenverkauf');
                            }else if (this.$store.getters['permissions/checkModule'](1)){
                                this.$router.replace('/pos/retail');
                            }
                        },
                        preDeny: () =>{
                            this.$router.replace('/dashboards/analytics')
                        },
                        allowOutsideClick: () => false,
                    });
                }
            }).catch((err) => {
                console.log(err)
            })
        },

    },
    beforeRouteEnter(from,to,next) {

        next(vm=>{
            vm.getActiveShift();
        })

    }
}