<template>
  <div>
    <page-title :heading="$t('accounting.lang_proFormaInvoice')" :subheading="$t('accounting.lang_proFormaInvoice')" :icon=icon show-previous-button></page-title>

    <div class="app-main__inner">
      <EditProformatComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EditProformatComponent from "@/components/billing/proformat/EditProformatComponent";
import shiftMixin from "../../../mixins/pos/shiftMixin";



export default {
  name: "EditProformat",
  components: {EditProformatComponent, PageTitle},
  mixins:[shiftMixin],
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>

